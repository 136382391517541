<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: boolean // 외부 데이터(v-model)를 통해 메뉴를 열고 닫을 수 있습니다.
    maxWidth?: number
    minWidth?: number
    width?: number | 'auto'
    x?: number
    y?: number
    margin?: number
    fixed?: boolean
    top?: boolean
    bottom?: boolean
    left?: boolean
    right?: boolean
  }>(),
  {
    maxWidth: 9999,
    minWidth: 0,
    width: 'auto',
    x: 0,
    y: 0,
    margin: 20,
    fixed: true,
    top: true,
    bottom: false,
    left: true,
    right: false
  }
)
const emit = defineEmits([
  'update:modelValue',
  'menu:opened', // 메뉴가 열리거나 닫힐 때마다 화면 갱신 후 이벤트를 발생!
  'menu:closed'
])

const menuEl = ref<HTMLDivElement | null>(null)
const menuStyle = ref({})

onMounted(() => {})

function escKeydownWidnow(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    offMenu()
  }
}
function onMenu(event: Event) {
  if (props.modelValue) {
    offMenu()
    return
  }

  emit('update:modelValue', true)
  window.addEventListener('keydown', escKeydownWidnow)
  const activatorRect = (
    event.currentTarget as HTMLElement
  ).getBoundingClientRect()

  nextTick(() => {
    // document.body.style.overflow = 'hidden'
    const menuRect = (menuEl.value as HTMLElement).getBoundingClientRect()
    const winW = window.innerWidth
    const winH = window.innerHeight
    const x = props.margin + props.x
    const y = props.margin + props.y

    // bottom
    if (props.bottom) {
      const pos = winH - activatorRect.top + props.y
      pos + menuRect.height > winH
        ? Object.assign(menuStyle.value, {
            top: `${y}px`,
            bottom: 'initial'
          })
        : pos < 0
          ? Object.assign(menuStyle.value, {
              top: 'initial',
              bottom: `${y}px`
            })
          : Object.assign(menuStyle.value, {
              top: 'initial',
              bottom: `${pos}px`
            })
    }
    // top
    if (props.top && !props.bottom) {
      const pos = activatorRect.bottom + props.y
      pos + menuRect.height > winH
        ? Object.assign(menuStyle.value, {
            top: 'initial',
            bottom: `${y}px`
          })
        : pos < 0
          ? Object.assign(menuStyle.value, {
              top: `${y}px`,
              bottom: 'initial'
            })
          : Object.assign(menuStyle.value, {
              top: `${pos}px`,
              bottom: 'initial'
            })
    }
    // left
    if (props.left && !props.right) {
      const pos = activatorRect.left + props.x
      pos + menuRect.width > winW
        ? Object.assign(menuStyle.value, {
            left: 'initial',
            right: `${x}px`
          })
        : pos < 0
          ? Object.assign(menuStyle.value, {
              left: `${x}px`,
              right: 'initial'
            })
          : Object.assign(menuStyle.value, {
              left: `${pos}px`,
              right: 'initial'
            })
    }
    // right
    if (props.right) {
      const pos = winW - activatorRect.right + props.x
      pos + menuRect.width > winW
        ? Object.assign(menuStyle.value, {
            left: `${x}px`,
            right: 'initial'
          })
        : pos < 0
          ? Object.assign(menuStyle.value, {
              left: 'initial',
              right: `${x}px`
            })
          : Object.assign(menuStyle.value, {
              left: 'initial',
              right: `${pos}px`
            })
    }

    emit('menu:opened')
  })
}
function offMenu() {
  window.removeEventListener('keydown', escKeydownWidnow)
  emit('update:modelValue', false)
  setTimeout(() => {
    // document.body.style.overflow = 'visible'
    emit('menu:closed')
  })
}
</script>

<template>
  <slot
    name="activator"
    :on="{
      click: onMenu
    }"></slot>
  <Teleport to="body">
    <div v-if="modelValue">
      <div
        class="the-menu-bg"
        @click.stop="offMenu"></div>
      <div
        ref="menuEl"
        :style="{
          ...menuStyle,
          maxWidth: `${maxWidth}px`,
          minWidth: `${minWidth}px`,
          width: typeof width === 'number' ? `${width}px` : width
        }"
        :class="{ fixed }"
        class="the-menu"
        @click.stop>
        <slot :off-menu="offMenu"></slot>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.the-menu-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
}
.the-menu {
  position: fixed;
  z-index: 99;
}
</style>
